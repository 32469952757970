import { getIngoLeaderBoards } from 'actions/Actions';
import Header from 'components/header/Header';
import LeaderboardList from 'components/leaderboardList/LeaderboardList';
import DataFetching from 'components/ui/DataFetching';
import ErrorDisplay from 'components/ui/ErrorDisplay';
import { ILeaderBoard } from 'interfaces/LeaderBoard';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';

const IngoLeaderboardPage = () => {
  const [searchValue, setSearchValue] = useState<String>('');
  const [searchParams] = useSearchParams();

  const loadLeaderBoard = async () => {
    let countryCode: string = searchParams.get('r') || '';
    if (countryCode !== 'dk') {
      countryCode = 'se';
    }
    const result = await getIngoLeaderBoards(countryCode);
    return result.data;
  };
  const { isLoading, error, data } = useQuery('loadLeaderBoard', loadLeaderBoard, {
    refetchOnWindowFocus: false,
    enabled: true,
  });
  const leaderboardList:ILeaderBoard[] = data as ILeaderBoard[];
  const search = (value: string):void => {
    setSearchValue(value);
  };
  const filterLeaderBoard = ():ILeaderBoard[] => {
    return leaderboardList.filter((item:ILeaderBoard) => item.username.toLowerCase().includes(searchValue.toLowerCase())) || [];
  };
  return (
    <div>
      <Header onSearch={search} />
      {error && <ErrorDisplay />}
      {isLoading && <DataFetching />}
      {!error && !isLoading && leaderboardList && (
      <div className="max-w-[1000px] ml-auto mr-auto">
        <LeaderboardList data={filterLeaderBoard()} />
      </div>
      )}
    </div>
  );
};

export default IngoLeaderboardPage;

import actionTypes from 'context/actionTypes';

export const gameInitialState = {
  token: '',
  user: null,
  gameData: null,
  userInfo: null,
};
export const gameReducer = (state = gameInitialState, action: any = undefined) => {
  switch (action.type) {
    case actionTypes.CACHE_GAME_HIGH_SCORE: {
      return {
        ...state,
        gameData: action.payload,
      };
    }
    default:
      return state;
  }
};

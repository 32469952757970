import AppRoutes from 'routes/AppRoutes';
import setAxiosConfig from 'services/AxiosConfig';
import { QueryClient, QueryClientProvider } from 'react-query';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import dkLanguage from './assets/language/dk.json';
import seLanguage from './assets/language/se.json';

const queryClient = new QueryClient();

const App = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const language = queryParams.get('r');
  i18n.use(initReactI18next).init({
    lng: language || 'se',
    debug: true,
    resources: {
      dk: {
        translation: dkLanguage,
      },
      se: {
        translation: seLanguage,
      },
    },
    fallbackLng: 'se',
    interpolation: {
      escapeValue: false,
    },
  });

  setAxiosConfig();
  return (
    <QueryClientProvider client={queryClient}>
      <AppRoutes />
    </QueryClientProvider>
  );
};

export default App;

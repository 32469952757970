import React, { useContext, FC, useReducer } from 'react';
import { gameInitialState } from 'context/reducers/gameReducer';
import reducers from 'context/reducers';

export const initialState = {
  ...gameInitialState,
};

interface Props { }
const AppContext = React.createContext({});
const AppProvider: FC<Props> = ({ children }) => {
  const [state, dispatch] = useReducer(reducers, initialState);
  return (
    <AppContext.Provider
      value={{
        ...state,
        dispatch,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
// make sure use
export const useAppContext = () => {
  return useContext(AppContext);
};

export { AppProvider, AppContext };

import {
  BrowserRouter, Route, Routes,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Suspense } from 'react';
import IngoLeaderboardPage from 'pages/IngoLeaderboardPage';

const AppRoutes = () => {
  return (
    <BrowserRouter>
      <ToastContainer position="top-center" />
      <Suspense fallback={<div>Loading...</div>}>
        <Routes>
          <Route path="" element={<IngoLeaderboardPage />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;

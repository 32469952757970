import React from 'react';

interface Props {
  text?: string;
  className?: string;
}

const DataFetching: React.FC<Props> = ({
  text = 'Loading...',
  className = '',
}) => {
  return (
    <div className={className}>
      <h2 className="text-white text-center pt-4 pb-4">{text}</h2>
    </div>
  );
};

export default DataFetching;

import { FC, KeyboardEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onSearch: (value: string) => void
}
const currentWeekNumber = require('current-week-number');

const Header:FC<Props> = ({
  onSearch,
}) => {
  const { t } = useTranslation();
  const [value, setValue] = useState<string>('');
  const onSubmit = ():void => {
    onSearch(value);
  };
  const onClear = ():void => {
    setValue('');
    onSearch('');
  };
  const handleKeyDown = (e:KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      onSearch(value);
    }
  };
  return (
    <div className="container mt-30">
      <div className="row">
        <div className="col-8">
          <h1 className="display-4">{t('winners')}</h1>
          <h4 id="c">{t('week')} <span>{ Number(currentWeekNumber()) > 10 ? Number(currentWeekNumber() - 1) : `0${Number(currentWeekNumber() - 1)}`}</span></h4>
          <div id="users-form" className="block">
            <div className="form-row">
              <div className="col-5">
                <input type="text" id="nickname" className="form-control form-control-md" onKeyDown={(e) => handleKeyDown(e)} placeholder={t('searchbar')} value={value} onChange={(e) => setValue(e.target.value)} />
              </div>
              <div className="col-auto">
                <button className="btn btn-light btn-md" type="button" onClick={onSubmit}>{t('search')}</button>&nbsp;
                {value && <button className="btn btn-light btn-md" type="button" onClick={onClear}>{t('clear')}</button>}
              </div>
            </div>
          </div>
        </div>
        <div className="col-4">
          <div id="prize-symbol">
            <img src="/prize.svg" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Header;

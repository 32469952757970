import { ILeaderBoard } from 'interfaces/LeaderBoard';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  data: ILeaderBoard[];
}
const LeaderboardList:FC<Props> = ({
  data,
}) => {
  const { t } = useTranslation();
  const getLiter = (rank: number): number => {
    if (rank === 1) return 50;
    if (rank === 2) return 40;
    if (rank === 3) return 30;
    if (rank > 3 && rank <= 10) return 10;
    if (rank > 10 && rank <= 100) return 5;
    if (rank > 100 && rank <= 1000) return 1;
    return 0;
  };
  return (
    <div className="container">
      <div className="table-responsive" id="results-table">
        <table className="table">
          <thead>
            <tr>
              <th>{t('ranking')}</th>
              <th>{t('name')}</th>
              <th>{t('won')}</th>
            </tr>
          </thead>
          <tbody>
            {
              data.map((item:ILeaderBoard) => (
                <tr key={item.id}>
                  <td className="stand">{item.rank}</td>
                  <td className="name">{item.username}</td>
                  <td className="liter">
                    { getLiter(item.rank) }
                    &nbsp;liter
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LeaderboardList;

import { gameReducer, gameInitialState } from 'context/reducers/gameReducer';

export const initialState = {
  ...gameInitialState,
};

// TODO fix type
const combineReducers = (...reducers: any) => (state = initialState, action: any = null) => {
  for (let i = 0; i < reducers.length; i++) {
    state = reducers[i](state, action);
  }

  return state;
};

export default combineReducers(
  gameReducer,
);
